import React from "react";
import { PrimaryButton, SecondaryButton } from "../../common/Button";
import { AppContainer } from "../AppContainer";
import dashboardFrame from "../../../assets/images/dashboard-frame.png";
import { Link } from "react-router-dom";
import { SocialIconsComponent } from "./SocialIconsComponent";
import ScrollToHref from "../../common/ScrollToHref";
import TypeWriter from "typewriter-effect";
export const HeroSection = (): React.ReactElement => {
  return (
    <div
      className="w-full"
      style={{
        backgroundImage:
          "linear-gradient(174deg, #040710 56%, #101123 calc(56% + 2px)) ",
      }}
    >
      <AppContainer>
        <div className="flex flex-col lg:pt-16 pb-10">
          <div className="flex flex-col">
            <div className="flex flex-col w-full pt-16 lg:m-0 text-center">
              <div className="max-w-5xl mx-auto">
                <h4
                  data-aos="fade-right"
                  data-aos-delay="500"
                  className="font-bold leading-tight mb-5 app-font"
                >
                  <span className="text-3xl md:text-6xl">
                    We Offer The{" "}
                    <span style={{ textShadow: "0 2px 12px #f0f" }}>
                      Lowest Rates
                    </span>{" "}
                    For Your Launch
                  </span>
                </h4>
              </div>
              <div className="max-w-3xl mx-auto">
                <h3
                  data-aos="fade-right"
                  data-aos-delay="700"
                  className="text-base md:text-xl font-medium mb-10 text-gray-400"
                >
                  <TypeWriter
                    options={{
                      loop: true,
                    }}
                    onInit={(typewriter) => {
                      typewriter
                        .changeDelay(20)
                        .typeString(
                          "A blockchain launchpad is a platform that helps new blockchain projects and startups launch their initial coin offerings "
                        )
                        .pauseFor(1000)
                        .deleteAll(10)
                        .start();
                    }}
                  />
                </h3>
              </div>
              <div className="flex space-x-4 mb-8 justify-center">
                <Link
                  to="/app/dashboard"
                  target="_blank"
                  data-aos="fade"
                  data-aos-delay="800"
                >
                  <SecondaryButton>Launch App ARB</SecondaryButton>
                </Link>
                <Link
                  to="https://eth.vaultmatrix.io/"
                  target="_blank"
                  data-aos="fade"
                  data-aos-delay="800"
                >
                  <SecondaryButton>Launch App ETH</SecondaryButton>
                </Link>
                <Link
                  to="https://bsc.vaultmatrix.io/"
                  target="_blank"
                  data-aos="fade"
                  data-aos-delay="800"
                >
                  <SecondaryButton>Launch App BSC</SecondaryButton>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    </div>
  );
};
